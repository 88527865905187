import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { set_user } from '../Store/reducers/userReducer';
import api from '../helpers/axios-interceptor/api';

type ProtectedRouteType = {
  children: React.ReactElement;
};

const ProtectedRoute: React.FC<ProtectedRouteType> = ({ children }: ProtectedRouteType) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const dispatch = useDispatch();
  const route = useNavigate();

  
  useEffect(() => {
    if (user?.id) {
      api.get(`/users/${user.id}`).then(res => {
        dispatch(set_user(res.data.data));
        localStorage.setItem('user', JSON.stringify(res.data.data));
      });
    }
  }, [dispatch, route, user]);

  if (!user?.id) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
