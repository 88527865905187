import { createTheme } from '@mui/material/styles';
const HeinekenCoreBold = require('./css/Fonts/Heineken-Core/HEINEKENCore-Bold.otf');
const HeinekenCoreBoldItalic = require('./css/Fonts/Heineken-Core//HEINEKENCore-BoldItalic.otf');
const HeinekenCoreLight = require('./css/Fonts/Heineken-Core/HEINEKENCore-Light.otf');
const HeinekenCoreLightItalic = require('./css/Fonts/Heineken-Core/HEINEKENCore-LightItalic.otf');
const HeinekenCoreMedium = require('./css/Fonts/Heineken-Core/HEINEKENCore.otf');
const HeinekenCoreMediumItalic = require('./css/Fonts/Heineken-Core/HEINEKENCore-Italic.otf');
const HeinekenCoreExtraBold = require('./css/Fonts/Heineken-Core/HEINEKENCore-ExtraBold.otf');

export const Theme = createTheme({
  palette: {
    primary: {
      main: '#13670B',
      light: '#0E4D08',
      dark: '#093305',
    },
    secondary: {
      main: '#DEE2E6',
    },
    error: {
      main: '#E20613',
    },
  },
  typography: {
    fontFamily: [
      'Heineken Core',
      'Heineken Core Light',
      'Heineken Core Bold',
      'Heineken Core Italic',
      'Heineken Core Light Italic',
      'Heineken Core Bold Italic',
      'Heineken Core Extra Bold',
    ].join(','),
    h1: {
      fontFamily: 'Heineken Core Bold',
      fontSize: 56,
      color: '#083D49',
      lineHeight: '65px',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: 'Heineken Core Bold',
      fontSize: 42,
      lineHeight: '49px',
      fontWeight: 500,
    },
    // Login page
    h3: {
      fontFamily: 'Heineken Core',
      fontSize: 34, 
      fontWeight: 700,
      lineHeight: 'normal',
    },
    // Sub-page title
    h4: {
      fontFamily: 'Heineken Core',
      fontSize: 34,
      fontWeight: 400,
      lineHeight: '42px',
      letterSpacing: 0.25,
    },
    h5: {
      fontFamily: 'Heineken Core Bold',
      fontSize: 18,
      lineHeight: '21px',
    },
    h6: {
      fontFamily: 'Heineken Core',
      fontSize: 14,
      lineHeight: '24px',
      color: '#000000',
    },
    subtitle1: {
      fontFamily: 'Heineken Core Bold',
      fontSize: '16px',
      lineHeight: '18px',
      color: '#083D49',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: 'Heineken Core Bold',
      fontSize: 14,
    },
    body1: {
      fontFamily: 'Heineken Core',
      fontSize: 16,
      lineHeight: '21px',
    },
    body2: {
      fontFamily: 'Heineken Core',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
    },
    button: {},
    caption: {
      fontFamily: 'Heineken Core Bold',
    },
    overline: {},
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          marginRight: 2,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          background-color: #ffffff !important;
        }
  
        @font-face{
          font-family:"Heineken Core Light";
          src:url(${HeinekenCoreLight}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Light Italic";
          src:url(${HeinekenCoreLightItalic}) format("opentype");
        }
  
       @font-face{
          font-family:"Heineken Core";
          src:url(${HeinekenCoreMedium}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Italic";
          src:url(${HeinekenCoreMediumItalic}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Bold";
          src:url(${HeinekenCoreBold}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Bold Italic";
          src:url(${HeinekenCoreBoldItalic}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Extra Bold";
          src:url(${HeinekenCoreExtraBold}) format("opentype");
        }
      `,
    },
    MuiTextField: {
      defaultProps: {
        style: {
          minHeight: 40,
          backgroundColor: '#BBBCBD',
          justifyContent: 'center',
          padding: '2px',
        },
        InputProps: {
          disableUnderline: true,
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        PaperProps: {
          style: {
            padding: 20,
            minWidth: 400,
            width: 400,
            height: '100%',
            backgroundColor: '#f8f9fa',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        style: {
          backgroundColor: 'white',
          padding: '3px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            background: '#FFF',
            borderRadius: 4,
            boxShadow: 'none',
            fontSize: 15,
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: 0.46,
            textTransform: 'uppercase',
            width: '100%',
            height: 42,
            color: '#13670B',
            padding: '8px 22px',
            '&:hover': {
              background: '#DEE2E6',
              color: '#13670B',
            },
            '&.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.46)',
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'capitalize',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 'normal',
            fontFamily: 'Heineken Core',
            borderRadius: '2px',
            color: 'rgba(255, 255, 255, 0.8)',
            height: 36,
            '&:hover': {
              color: '#FFF',
              borderRadius: '4px',
            },
          },
        },
      ],
    },
    MuiTab: {
      defaultProps: {
        style: {
          color: 'black',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
      },
    },
  },
});
