import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../Permissions/ProtectedRoute';
import SuspenseContainer from '../Components/Suspense/SuspenseContainer';
import Admin from '../pages/Admin/Admin';
import AdminProtectedRoute from '../Permissions/AdminProtectedRoute';

const Sponsorships = lazy(() => import('../pages/Sponsorships/Sponsorships'));
const Submit = lazy(() => import('../pages/Sponsorships/Submit/Submit'));
const Benchmarking = lazy(() => import('../pages/Benchmarking/Benchmarking'));
const BenchmarkingReview = lazy(() =>
  import('../pages/Benchmarking/BenchmarkingReview/BenchmarkingReview'),
);
const AudienceInsight = lazy(() => import('../pages/AudienceInsight/AudienceInsight'));
const AudienceInsightDetails = lazy(() =>
  import('../pages/AudienceInsight/AudienceInsightDetails/AudienceInsightDetails'),
);

const NoMatch = lazy(() => import('../Components/NoMatch/NoMatch'));
const NewPassword = lazy(() => import('../UserAuthentication/Components/NewPassword/NewPassword'));
const ResetPassword = lazy(() => import('../UserAuthentication/Components/ResetPassword/ResetPassword'));
const Login = lazy(() => import('../UserAuthentication/Components/Login/Login'));

const AllRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <SuspenseContainer>
            <Login />
          </SuspenseContainer>
        }
      />
      <Route
        path="/sponsorships"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Sponsorships />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/sponsorships/:id/submit"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Submit />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/benchmarking"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Benchmarking />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/benchmarking/:id/*"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <BenchmarkingReview />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />      
      <Route
        path="/audience-insight"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <AudienceInsight />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/audience-insight/:id"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <AudienceInsightDetails />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Admin />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      {/*TODO: Remove comment when user has role
      <Route
        path="/admin"
        element={
          <AdminProtectedRoute>
            <SuspenseContainer>
              <Admin />
            </SuspenseContainer>
          </AdminProtectedRoute>
        }
      />
      */}
      <Route
        path="/new-password/:token"
        element={
          <SuspenseContainer>
            <NewPassword />
          </SuspenseContainer>
        }
      />
      <Route
        path="/reset"
        element={
          <SuspenseContainer>
            <ResetPassword />
          </SuspenseContainer>
        }
      />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default AllRoutes;
