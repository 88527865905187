import api from '../../../../../helpers/axios-interceptor/api';
import {
  set_internal_artwork_versions,
  set_internal_comments,
  set_internal_decision_mode,
  set_internal_reviewers,
  set_project_data_internal,
} from '../../../../../Store/reducers/projectReducer';
import { set_artwork_versions } from '../../../../../Store/reducers/proofingReducer';
import { setErrorToastState } from '../../../../../Store/action';
import { AxiosPromise } from 'axios';
import { AppDispatch } from '../../../../../Store/Store';

export const uploadArtworkInternalReview = (data: any): AxiosPromise => {
  return api.post('/brand-request-artwork-versions', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getInternalReviewers = (search: string): AxiosPromise => {
  return api.get(`/users?role=admin&search=${search}`);
};

export const addInternalComments = (id: string | number, data: any): AxiosPromise => {
  return api.post(`/brand-request/${id}/internal-review/comments`, data);
};

export const addInternalReviewer = (data: any): AxiosPromise => {
  return api.post(`/brand-request-artwork-versions/internal-review/reviewers`, data);
};

export const removeInternalReviewer = (data: any): AxiosPromise => {
  return api.post(`/brand-request-artwork-versions/internal-review/reviewers?_method=delete`, data);
};

export const setReviewersDecisionAccept =
  (brand_request_id: string | number) => (dispatch: AppDispatch) =>
    api.post(`/brand-request/${brand_request_id}/internal-review/accept`).then(res => {
      if (res.status === 200) {
        dispatch(handleGetInternalProjectData(brand_request_id));
      }
    });

export const setReviewersDecisionDecline =
  (brand_request_id: string | number) => (dispatch: AppDispatch) =>
    api.post(`/brand-request/${brand_request_id}/internal-review/decline`).then(res => {
      if (res.status === 200) {
        dispatch(handleGetInternalProjectData(brand_request_id));
      }
    });

export const handleSetArtworkVersions = (versions: any) => (dispatch: AppDispatch) =>
  dispatch(set_artwork_versions(versions));

export const handleGetInternalProjectData = (id: string | number) => (dispatch: AppDispatch) => {
  fetchInternalProjectData(id)
    .then(res => {
      if (res.status === 200) {
        dispatch(set_project_data_internal(res.data.data));
        dispatch(set_internal_artwork_versions(res.data.data.artworkVersions));
        dispatch(set_artwork_versions(res.data.data.artworkVersions));
        dispatch(set_internal_comments(res.data.data.internalComments));
        dispatch(set_internal_reviewers(res.data.data.artworkInternalReviewers));
        dispatch(set_internal_decision_mode(res.data.data.internal_review_pass_one_required));
      }
    })
    .catch(err => {
      dispatch(setErrorToastState(true, 'Something went wrong, please try again in a while.'));
    });
};

export const fetchInternalProjectData = (id: string | number): AxiosPromise => {
  return api.get(`/brand-request/${id}/internal`);
};

export const getInternalComments = (id: string | number): AxiosPromise => {
  return api.get(`/brand-request/${id}/internal`);
};

export const updateInternalReviewerDecisionMode = (
  id: string | number,
  state: boolean,
): AxiosPromise => {
  return api.post(`/brand-request/${id}/internal-review-pass-mode`, {
    internal_review_pass_one_required: state,
  });
};
