import React, { SyntheticEvent, useEffect, useState, lazy, Suspense } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Routes, Link, Route, Navigate, useLocation } from 'react-router-dom';
import UserTable from './user-table/user-table.component';
import CreateUser from './user/create-user/create-user.component';
import DeleteUser from './user/delete-user/delete-user.component';
import EditUser from './user/edit-user/edit-user.component';
import UserDetails from './user/user-details/user-details.component';
import { set_create_user_drawer } from '../../Store/reducers/adminReducer';

import { AppDispatch, RootState } from '../../Store/Store';

const General = lazy(() => import('./pages/General/General'));
const Users = lazy(() => import('./pages/Users/Users'));
const Markets = lazy(() => import('./pages/Markets/Markets'));
const Platforms = lazy(() => import('./pages/Platforms/Platforms'));

interface TabPanelProps {
  children: React.ReactElement;
  index?: number;
  value?: number;
}

const Admin: React.FC = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const { tab } = params;
  const user = useSelector(({ user }: RootState) => user.user);

  useEffect(() => {
    if (tab && selectedTab === null) {
      setSelectedTab(Number(tab));
    }
  }, [tab, selectedTab]);

  useEffect(() => {
    if (selectedTab) {
      router(`/admin?tab=${selectedTab}`);
    }
  }, [selectedTab, router]);

  const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  return (
    <Grid container direction="column" style={{ padding: 30 }}>
      
      <Grid item>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" fontWeight={700}>
              Admin 
            </Typography>
          </Grid>            
        </Grid>
      </Grid>

      <Grid item sx={{ height: '23px', borderBottom: '1px solid #D3D3D3' }}>
        <Grid container direction="row" gap="39px" sx={{ width: '90%', margin: '0 auto' }}>
          <Link
            to="general"
            style={{
              textDecoration: 'none',
            }}>
            <Typography
              sx={{
                cursor: 'pointer',
                fontSize: '14px',
                color: params['*'] === 'general' ? '#E1261C' : '#011E41',

                borderBottom: params['*'] === 'general' ? '2px solid #E1261C' : 'none',
              }}>
              General
            </Typography>
          </Link>
        
            <Link
              to="users"
              style={{
                textDecoration: 'none',
              }}>
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  color: params['*'] === 'users' ? '#E1261C' : '#011E41',
                  borderBottom: params['*'] === 'users' ? '2px solid #E1261C' : 'none',
                }}>
                Users
              </Typography>
            </Link>
            
          {/*TODO: Remove comment when user has admin permissions
          user?.is_super_admin && (
            <Link
              to="users"
              style={{
                textDecoration: 'none',
              }}>
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  color: params['*'] === 'users' ? '#E1261C' : '#011E41',
                  borderBottom: params['*'] === 'users' ? '2px solid #E1261C' : 'none',
                }}>
                Users
              </Typography>
            </Link>
              )*/}

          <Link
            to="markets"
            style={{
              textDecoration: 'none',
            }}>
            <Typography
              sx={{
                cursor: 'pointer',
                fontSize: '14px',
                color: params['*'] === 'markets' ? '#E1261C' : '#011E41',
                borderBottom: params['*'] === 'markets' ? '2px solid #E1261C' : 'none',
              }}>
              Markets
            </Typography>
          </Link>

          <Link
            to="platforms"
            style={{
              textDecoration: 'none',
            }}>
            <Typography
              sx={{
                cursor: 'pointer',
                fontSize: '14px',
                color: params['*'] === 'platforms' ? '#E1261C' : '#011E41',
                borderBottom: params['*'] === 'platforms' ? '2px solid #E1261C' : 'none',
              }}>
              Platforms
            </Typography>
          </Link>
        </Grid>
      </Grid>

      <Grid item>
        <Routes>
          <Route path="general" element={<General />} />
          <Route path="users" element={<Users />} />
          <Route path="markets" element={<Markets />} />
          <Route path="platforms" element={<Platforms />} />
          <Route path="*" element={<Navigate to="general" />} />
        </Routes>
      </Grid>
      
    </Grid>
      
    
  );
};

export default Admin;
